import React from "react";
import { Element } from "react-scroll";
import "../styles/Products.css";

const Products = () => {
  const products = [
    { 
      id: 1, 
      name: "Clinical Management Systems (CMS)", 
      description: "We provide Clinical Management Systems (CMS) for clinics and hospitals, offering web and mobile applications for patients and physicians. Our solutions ensure transparency, ease physician work, and enhance patient trust." 
    },
    { 
      id: 2, 
      name: "DB Analytics Agent", 
      description: "Leverage the power of Large Language Models with our DB Analytics Agent. Gain comprehensive insights and visual representations of your data, enabling you to query and analyze your database with natural language." 
    },
    { 
      id: 3, 
      name: "Video Analytics Software", 
      description: "Implement intelligent video surveillance with our Video Analytics Software. Leveraging AI, it analyzes CCTV footage to identify and alert you to potential crimes, thefts, and suspicious behavior." 
    },
  ];

  return (
    <Element name="products" className="products-container">
      <h1 className="products-title">Our Software Products</h1>
      <div className="products-grid">
        {products.map((product) => (
          <div className="product-card" key={product.id}>
            <div className="product-card-content">
              <h2>{product.name}</h2>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Element>
  );
};

export default Products;
