// footer.js
import React from "react";
import "../styles/Footer.css";
import { FaInstagram, FaLinkedin } from "react-icons/fa"; // Import icon components

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Flores Innovative Solutions. All rights reserved.</p>
        <nav className="social-links" aria-label="Social Media Links">
          <a 
            href="https://www.instagram.com/flores_innovative_solutions" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Visit our Instagram page"
          >
            <FaInstagram className="social-icon" /> {/* Use icon component */}
          </a>
          <a
            href="https://in.linkedin.com/company/flore-innovative-solutions-private-limited"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit our LinkedIn page"
          >
            <FaLinkedin className="social-icon" /> {/* Use icon component */}
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;