import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "../styles/Navbar.css";
import logo from "../assets/logo.png"; // Ensure your logo is in the assets folder

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      {/* Clickable Logo (Scroll to Home) */}
      <div className="logo">
        <Link to="home" smooth={true} duration={500} offset={-80} className="cursor-pointer">
          <img src={logo} alt="Flores Logo" />
        </Link>
      </div>

      {/* Hamburger Menu (for mobile) */}
      <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        ☰
      </div>

      {/* Navigation Links */}
      <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
        <li><Link to="home" smooth={true} duration={500} offset={-70} onClick={() => setMenuOpen(false)}>Home</Link></li>
        <li><Link to="products" smooth={true} duration={500} offset={-70} onClick={() => setMenuOpen(false)}>Products</Link></li>
        <li><Link to="services" smooth={true} duration={500} offset={-70} onClick={() => setMenuOpen(false)}>Services</Link></li>
        <li><Link to="about-us" smooth={true} duration={500} offset={-70} onClick={() => setMenuOpen(false)}>About Us</Link></li>
        <li><Link to="contact-us" smooth={true} duration={500} offset={-70} onClick={() => setMenuOpen(false)}>Contact Us</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
