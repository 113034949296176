import React from "react";
import { Element } from "react-scroll";
import "../styles/Home.css";
import backgroundVideo from "../assets/homebgv.mp4"; // Ensure this video is in the assets folder

const Home = () => {
  return (
    <Element name="home" className="home-container">
      {/* Background Video */}
      <video className="background-video" autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Home Content */}
      <div className="content">
        <h1>Welcome to Flores Innovative Solutions</h1>
        <p>One-stop solution for all your needs.</p>
        <button className="cta-button">Explore More</button>
      </div>
    </Element>
  );
};

export default Home;
