import React, { useState } from "react";
import { Element } from "react-scroll";
import "./../styles/ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    industry: "",
    phone: "",
    description: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted successfully!");
  };

  const handleReset = () => {
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      industry: "",
      phone: "",
      description: "",
    });
  };

  return (
    <Element name="contact-us" className="contact-container">
      <div className="contact-info">
        <h2>Our dedicated team is here to assist you.</h2>

        <div className="info-section">
          <div className="icon">📧</div>
          <div>
            <h3>Send your queries to</h3>
            <p className="p">hr@floresinnovatives.com</p>
          </div>
        </div>

        <div className="info-section">
          <div className="icon">📞</div>
          <div>
            <h3>Reach out at</h3>
            <p className="p">+91 6303679126</p>
            <p className="p">+971 58 937 3643</p>
          </div>
        </div>

        {/* Headquarters Section */}
        <div className="info-section">
          <div className="icon">📍</div>
          <div>
            <h3>Headquarters</h3>
            <p className="p">7-1-256/1, Laxmipuram, Hanamakonda, Warangal, Telangana, India, 506001</p>
            <iframe
              title="Headquarters Map"
              src="https://www.google.com/maps?q=7-1-256/1,+Laxmipuram,+Hanamakonda,+Warangal,+Telangana,+India,+506001&output=embed"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* Operational Office Section */}
        <div className="info-section">
          <div className="icon">📍</div>
          <div>
            <h3>Operational Office</h3>
            <p className="p">Yellammabanda, Kukatpally, Hyderabad, India, 500072</p>
            <iframe
              title="Operational Office Map"
              src="https://www.google.com/maps?q=Yellammabanda,+Kukatpally,+Hyderabad,+India,+500072&output=embed"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First Name <span>*</span></label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Last Name <span>*</span></label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Company <span>*</span></label>
            <input type="text" name="company" value={formData.company} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Industry <span>*</span></label>
            <select name="industry" value={formData.industry} onChange={handleChange} required>
              <option value="">-None-</option>
              <option value="IT">IT</option>
              <option value="Finance">Finance</option>
              <option value="Healthcare">Healthcare</option>
            </select>
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea name="description" value={formData.description} onChange={handleChange}></textarea>
          </div>
          <div className="button-group">
            <button type="submit">Submit</button>
            <button type="button" onClick={handleReset}>Reset</button>
          </div>
        </form>
      </div>
    </Element>
  );
};

export default ContactUs;
