import React from "react";
import { Element } from "react-scroll";
import "../styles/Services.css";
import professionalDevelopmentImg from "../assets/professional-development.png"; // Corrected import
import customerServicetImg from "../assets/customer-service.png"; // Corrected import
import workforceImg from "../assets/workforce.png"; // Corrected import

const Services = () => {
  return (
    <Element name="services" className="services-container">
      <h1 className="services-title">Why Choose Our Services?</h1>
      <p className="services-subtitle">Powerful Alone, Unstoppable Together</p>

      <div className="services-grid">
        <div className="service-card">
          <div className="service-card-content">
            <img src={customerServicetImg} alt="BPO" className="service-icon" />
            <h2>BPO</h2>
            <p>
              We provide customer support services for businesses, including call handling for hospitals, clinics,
              e-commerce businesses, and retailers, ensuring seamless customer interactions.
            </p>
          </div>
        </div>

        <div className="service-card">
          <div className="service-card-content">
            <img src={professionalDevelopmentImg} alt="Professional Solutions" className="service-icon" /> 
            <h2>Professional, Personal Solutions</h2>
            <p>
              We transform our clients' digital dreams into reality, working closely with users throughout development
              to align with business goals and deliver high-quality software solutions.
            </p>
          </div>
        </div>

        <div className="service-card">
          <div className="service-card-content">
            <img src={workforceImg} alt="Global Workforce" className="service-icon" />
            <h2>Global Workforce Solutions</h2>
            <p>
              We specialize in providing skilled and non-skilled workforce to companies worldwide, ensuring businesses
              get the right talent efficiently.
            </p>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Services;
