// about.js
import React from "react";
import { Element } from "react-scroll";
import "../styles/AboutUs.css";
import founderImage from "../assets/suman.jpeg";

const AboutUs = () => {
  return (
    <Element name="about-us" className="about-container">
      <div className="about-content">
        <h1 className="section-title">About Us</h1>
        <p className="section-description">
          We are a team dedicated to delivering the best solutions.
        </p>
        
        <div className="about-section">
          <div className="about-text">
            <p>
              Why Us? Young and rapidly growing team with a passion for leveraging
              technology to solve healthcare issues. Our team thrives on innovation, independent thinking,
              and entrepreneurial spirit.
            </p>
          </div>
          {/* <div className="about-image">
            <img src={founderImage} alt="Team" />
          </div> */}
        </div>
        
        <div className="mission-section">
          {/* <div className="mission-image">
            <img src={founderImage} alt="Mission" />
          </div> */}
          <div className="mission-text">
            <h2>Our Mission: Helping Millions of Organizations Grow Better</h2>
            <p>
              We believe not just in growing bigger, but in growing better. Aligning business success
              with customer success is our top priority.
            </p>
          </div>
        </div>
        
        <div className="team-section">
          <h2 className="team-title">Our Team</h2>
          <div className="team-member">
            <div className="team-image">
              <img src={founderImage} alt="Founder" />
            </div>
            <div className="team-text">
              <h3>Founder: Suman Dongala</h3>
              <p className="p">
                Visionary leader with a background in healthcare and technology innovation.
                Suman founded the company with the aim to revolutionize healthcare technology
                through cutting-edge solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default AboutUs;
